import { ToastEnum, useErrorHandlerStore } from '@/composables/stores/error-handler';
import { FormKitNode } from '@formkit/core';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { Fields } from '~/composables/types/AssistanceRequest';
import { Role } from '~/composables/types/Role';
import { EnumAssistanceRequestStatusUserFriendlyJson, EnumFaqSection } from '../composables/Enums';
import { useDynamicFormStore } from '../composables/stores/DynamicFormStore';
import type {FormFieldLabelValue} from "~/composables/types/DynamicForm";

interface GroupByInput {
  [key: string]: any;
}

interface GroupByOutput {
  [key: string]: GroupByInput[];
}

interface GroupedItems {
  [key: string]: any;
}

interface Item {
  [key: string]: any;
}

export const globalFetchOptions = () => {
  const config = useRuntimeConfig();
  // check cookie.value if value does not exist then take the value from localStorage
  const cookie = exportUserSession();
  return {
    baseURL: config.public.adminApiBase,
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
  };
};

export function exportUserSession(): { value: string | null } {
  return { value: localStorage.getItem('user-token') };
}

export function destroyUserSession(): void {
  localStorage.removeItem('user-token');
}

export function setUserSession(session: string): void {
  localStorage.setItem('user-token', session);
}

export const globalUseQueryOptions = () => {
  return {
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0,
    enabled: true,
    retry: 0,
  };
};

export const generateFilter = (filterParam: {
  [key: string]: { value: string | number | boolean; operator: string; valueLabel?: string[] }[];
}) => {
  if (filterParam === undefined) return '';
  let arFilter = '';
  let index = 0;
  Object.keys(filterParam).forEach(function (filter: string) {
    filterParam[filter].forEach(function (filterItem: {
      value: string | number | boolean;
      operator: string;
      valueLabel?: string[];
    }) {
      // here filterItem.value can be of multiple types (string | number | boolean) type safety cant be used
      // eslint-disable-next-line eqeqeq
      if (filterItem.value != '') {
        let fvalue;
        if (Array.isArray(filterItem.value)) {
          fvalue = filterItem.value.join(',');
        } else {
          fvalue = filterItem.value;
        }

        if ((filter === 'createdAt' || filter === 'updatedAt') && filterItem.operator === 'IsLessThanOrEqualTo') {
          const date = new Date(fvalue as string);

          date.setDate(date.getDate() + 1);

          fvalue = date.toISOString().slice(0, 10);
        }

        let optionsLabel = '';
        if (filterItem.valueLabel !== undefined) {
          optionsLabel = `&filters[${index}].ValueLabel=${filterItem.valueLabel.join(',')}`;
        }

        let operator = filterItem.operator;
        if (filter === 'createdBySnapshotId' || filter === 'submittedBySnapshotId') {
          if (filterItem.value === 'Caseworker') {
            operator = 'IsNotEqualTo';
          }

          fvalue = null;
        }

        if (filter === 'submittedLanguageId') {
          if (filterItem.value.includes('en')) {
            fvalue = filterItem.value.map((item: any) => {
              return item === 'en' ? 'null' : item;
            });
          }
        }

        // don't want to pass in 'undefined' as a parameter
        if (
          typeof fvalue !== 'undefined' &&
          fvalue !== '' &&
          (fvalue !== null || filter === 'createdBySnapshotId' || filter === 'submittedBySnapshotId') &&
          fvalue !== 'undefined'
        ) {
          arFilter += `filters[${index}].Field=${filter}&filters[${index}].Label=${getLabelFromField(
            filter
          )}&filters[${index}].Value=${fvalue}&filters[${index}].Operation=${operator}${optionsLabel}&`;
        }

        index++;

        if (filter === 'submittedBySnapshotId' && filterItem.value === 'Applicant') {
          arFilter += `filters[${index}].Field=status&filters[${index}].Label=Status&filters[${index}].Value=Draft&filters[${index}].Operation=IsNotEqualTo&`;
          index++;
        }
      }
    });
  });
  return arFilter;
};

const getLabelFromField = (field: string) => {
  const labelFromField: { [key: string]: string } = {
    assignedTo: 'Assigned To',
    serviceAreaId: 'Service Area',
    status: 'Status',
    assistanceTypeId: 'Assistance Type',
    serviceAreaIds: 'Service Area',
    lastName: 'Last Name',
    firstName: 'First Name',
    payee: 'Payee',
    idPublic: 'ID',
    applicationIdPublic: 'applicationId',
    amountRequested: 'Amount Requested',
    amountApproved: 'Amount Approved',
    totalHouseholdMembers: 'Household Members',
    funds: 'Funds',
    fundingSources: 'Funding Sources',
    createdAt: 'Created Date',
    updatedAt: 'Updated Date',
    phoneNumber: 'Phone Number',
    hasOpenRequests: 'Has Open Requests',
    city: 'City',
    zipcode: 'Zipcode',
    state: 'State',
    emailAddress: 'Email Address',
    totalAssistanceRequests: 'Total Assistance Request',
    openAssistanceRequests: 'Open Assistance Request',
    name: 'Name',
    isActive: 'Active',
    currentBalance: 'Current Balance',
    balanceAwarded: 'Amount Awarded',
    balanceFunded: 'Issued Payments',
    pendingPayments: 'Pending Payments',
    balanceAvailable: 'Unspent Balance',
    zipCodes: 'ZipCode',
    fundingSourceID: 'Funding Source',
    active: 'Active',
    balanceAllocated: 'Current Allocation',
  };
  return labelFromField[field] ?? field;
};

export const generateColumns = (columnParam: { [key: string]: boolean }) => {
  const arColumns: string[] = [];
  let i = 0;
  Object.keys(columnParam).forEach(function (column: string) {
    if (columnParam[column]) {
      arColumns.push(`columns[${i}]=${column}`);
      i++;

      if (column === 'firstName') {
        arColumns.push(`columns[${i}]=lastName`);
        i++;
      } else if (column === 'address1') {
        arColumns.push(`columns[${i}]=address2`);
        i++;
        arColumns.push(`columns[${i}]=city`);
        i++;
        arColumns.push(`columns[${i}]=state`);
        i++;
        arColumns.push(`columns[${i}]=zipcode`);
        i++;
      }
    }
  });
  return arColumns.join('&');
};

export const arrayDifference = (a1: string[], a2: string[]) => {
  return a1.filter(function (item) {
    return a2.indexOf(item) === -1;
  });
};

export const arrayDifferenceNumber = (a1: number[], a2: number[]) => {
  return a1.filter(function (item) {
    return a2.indexOf(item) === -1;
  });
};

export const objectDifference = (obj1: { [key: number]: number[] }, obj2: { [key: number]: number[] }) => {
  for (const key in obj1) {
    console.log(obj1[key], '--', obj2[key]);
    console.log(arrayDifferenceNumber(obj1[key], obj2[key]));
  }
};

export const toastError = (title: string, message: string, timeout = 1000000) => {
  const useErrorHandler = useErrorHandlerStore();
  const fixedTimeout = 1000000; // this is to override any timeout passed in
  useErrorHandler.addToast({
    type: ToastEnum.error,
    title,
    message,
    timeout: fixedTimeout,
  });
};

export const toastSuccess = (title: string, message: string, timeout = 3000) => {
  const useErrorHandler = useErrorHandlerStore();
  const fixedTimeout = 3000; // this is to override any timeout passed in
  useErrorHandler.addToast({
    type: ToastEnum.success,
    title,
    message,
    timeout: fixedTimeout,
  });
};

export const toastWarning = (title: string, message: string, timeout = 1000000) => {
  const useErrorHandler = useErrorHandlerStore();
  const fixedTimeout = 1000000; // this is to override any timeout passed in
  useErrorHandler.addToast({
    type: ToastEnum.warning,
    title,
    message,
    timeout: fixedTimeout,
  });
};

export const groupByArray = (arr: GroupByInput[], attribute: string, k: string): GroupByOutput => {
  return arr.reduce((acc: GroupByOutput, obj: GroupByInput) => {
    const key = obj && obj[attribute][k];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const groupByAttribute = (arr: Item[], attribute: string, attributeValue: string): GroupedItems => {
  return arr.reduce((acc: GroupedItems, obj: Item) => {
    acc[obj[attribute]] = obj[attributeValue];
    return acc;
  }, {});
};
export const applicantPrefix = 'SS-';
export const generateApplicantId = (id = 0) => {
  const fullId = String(id).padStart(11, '0');
  const formattedId =
    fullId.substring(0, 2) + '-' + fullId.substring(2, 5) + '-' + fullId.substring(5, 8) + '-' + fullId.substring(8);
  return `${applicantPrefix}${formattedId}`;
};

export const assistanceRequestPrefix = 'AR-';
export const generateAssistanceRequestId = (id = 0) => {
  const fullId = String(id).padStart(11, '0');
  const formattedId =
    fullId.substring(0, 2) + '-' + fullId.substring(2, 5) + '-' + fullId.substring(5, 8) + '-' + fullId.substring(8);
  return `${assistanceRequestPrefix}${formattedId}`;
};

export const applicationPrefix = 'APP-';
export const generateApplicationId = (id = 0) => {
  if (id === 0) return '';
  const fullId = String(id).padStart(11, '0');
  const formattedId = fullId.substring(0, 5) + '-' + fullId.substring(5);
  return `${applicationPrefix}${formattedId}`;
};

export const formatNumberToMoney = (amount: number, currencySign = 'standard') => {
  if (amount === null || amount === undefined) return 0;

  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign,
  });
};

export const removeDollarSign = (amount: string | number) => {
  if (amount === null || amount === undefined) return 0;
  return amount.toString().replace('$', '');
};

export const convertToCents = (amount: string | number | undefined) => {
  if (amount === null || amount === undefined) return 0;
  if (typeof amount === 'string') {
    amount = parseInt(amount);
    // if amount is NaN, return 0
    if (isNaN(amount)) {
      return 0;
    }
  }
  return amount * 100;
};

export const convertFromCents = (amount: string | number) => {
  if (amount === null || amount === undefined) return 0;
  if (typeof amount === 'string') {
    amount = parseInt(amount);
    if (isNaN(amount)) {
      return 0;
    }
  }
  return amount / 100;
};

export const formatDateToUserTimezone = (isoString: string) => {
  if (isoString === null || isoString === undefined || isoString === '') return '';
  // Get the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the ISO string to the user's timezone
  const zonedDate = utcToZonedTime(isoString, userTimeZone);

  // Format the zoned date
  const output = format(zonedDate, 'MM/dd/yyyy hh:mm aa');

  return output;
};

export const formatDate = (dateTimeString: string | undefined, html = ' ') => {
  if (dateTimeString === undefined) return '';
  const dateTime = new Date(dateTimeString);
  const month = dateTime.getMonth() + 1;
  const day = dateTime.getDate();
  const year = dateTime.getFullYear();
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  return `${month}/${day}/${year}${html}${hours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
};

// expects a dateTimeString in the format of just YYYY-MM-DD and no time
export const formatDateForFilters = (dateTimeString: string | undefined) => {
  if (dateTimeString === undefined) return '';
  const parts = dateTimeString.split('-');
  if (parts.length < 3) return '';
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  return `${month}/${day}/${year}`;
};

export const getTimeZone = (dateTimeString: string | undefined) => {
  if (dateTimeString === undefined) return '';

  const date = new Date(dateTimeString);

  const timezoneOffsetString = date.toLocaleString('en-US', { timeZoneName: 'short', hour12: false }).split(' ')[2];

  const timezoneAbbreviationMap: { [key: string]: string } = {
    'GMT-12': 'IDL',
    'GMT-11': 'NUT',
    'GMT-10': 'HST',
    'GMT-9': 'AKST',
    'GMT-8': 'PST',
    'GMT-7': 'MST',
    'GMT-6': 'CST',
    'GMT-5': 'EST',
    'GMT-4': 'AST',
    'GMT-3': 'BRT',
    'GMT-2': 'GST',
    'GMT-1': 'CET',
    'GMT+0': 'GMT',
    'GMT+1': 'CET',
    'GMT+2': 'EET',
    'GMT+3': 'MSK',
    'GMT+4': 'AMT',
    'GMT+5': 'PKT',
    'GMT+6': 'BST',
    'GMT+7': 'ICT',
    'GMT+8': 'CST',
    'GMT+9': 'JST',
    'GMT+10': 'AEST',
    'GMT+11': 'AEDT',
    'GMT+12': 'NZDT',
  };

  return timezoneAbbreviationMap[timezoneOffsetString] || timezoneOffsetString;
};

export const formatDateString = (dateTimeString: string, formatString: string) => {
  if (dateTimeString === null || dateTimeString === undefined) return '';
  const date = parseISO(dateTimeString);
  return format(date, formatString);
};

// remove the user token from the cookie
export function clearUserSession() {
  // const cookie = useCookie('user-token', {
  //   sameSite: 'strict',
  // });
  // cookie.value = null;
  destroyUserSession();
}

export const downLoadBlob = (blob: Blob, fileName: string) => {
  window.open(URL.createObjectURL(blob), '_blank');
  // const url = window.URL.createObjectURL(new Blob([blob]));
  // const link = document.createElement('a');
  // link.href = url;
  // link.setAttribute('download', fileName);
  // document.body.appendChild(link);
  // link.click();
};

export const inputMaskCorrectValue = (value: string) => {
  const newValue = value.replace('$', '').replace(',', '');
  return (parseFloat(`0${newValue}`)* 100).toFixed(0);
};

export const makeDateRang = (date: string) => {
  const dateRange = {
    startDate: '',
    endDate: '',
  };

  const date1 = new Date();
  const getCurrentDate = {
    year: date1.getFullYear(),
    month: (date1.getMonth() + 1).toString().padStart(2, '0'),
    day: date1.getDate().toString().padStart(2, '0'),
    hour: date1.getHours(),
    minute: date1.getMinutes(),
  };

  if (date === 'day') {
    dateRange.startDate = `${getCurrentDate.year}-${getCurrentDate.month}-${getCurrentDate.day}`;
  } else if (date === 'month') {
    dateRange.startDate = `${getCurrentDate.year}-${getCurrentDate.month}-01`;
  } else if (date === 'year') {
    dateRange.startDate = `${getCurrentDate.year}-01-01`;
  }

  const tomorrow = new Date(date1);
  tomorrow.setDate(date1.getDate() + 1);

  getCurrentDate.year = tomorrow.getFullYear();
  getCurrentDate.month = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
  getCurrentDate.day = tomorrow.getDate().toString().padStart(2, '0');

  dateRange.endDate = `${getCurrentDate.year}-${getCurrentDate.month}-${getCurrentDate.day}`;

  return dateRange;
};

export const dashboardAppInProgress = [
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.Submitted],
    status: EnumAssistanceRequestStatus.Submitted,
    value: 0,
    color: '#5C7BD9',
    icon: 'material-symbols:send-time-extension',
  },
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.ReturnedToApplicant],
    status: EnumAssistanceRequestStatus.ReturnedToApplicant,
    value: 0,
    color: '#9FE080',
    icon: 'material-symbols:settings-backup-restore',
  },
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.Accepted],
    status: EnumAssistanceRequestStatus.Accepted,
    value: 0,
    color: '#FFDC60',
    icon: 'material-symbols:interactive-space-sharp',
  },
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.ApprovalPending],
    status: EnumAssistanceRequestStatus.ApprovalPending,
    value: 0,
    color: '#FF7070',
    icon: 'ic:sharp-pending-actions',
  },
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.Approved],
    status: EnumAssistanceRequestStatus.Approved,
    value: 0,
    color: '#3EA25C',
    icon: 'ic:round-check-circle',
  },
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.ApprovedReceivedByFinance],
    status: EnumAssistanceRequestStatus.ApprovedReceivedByFinance,
    value: 0,
    color: '#73C0DE',
    icon: 'icon-park-outline:paper-money-two',
  },
  {
    name: EnumAssistanceRequestStatusUserFriendly[EnumAssistanceRequestStatus.ApprovedClosed],
    status: EnumAssistanceRequestStatus.ApprovedClosed,
    value: 0,
    color: '#FF915A',
    icon: 'material-symbols:curtains-closed',
  },
  // {name: 'Abandoned', status: EnumAssistanceRequestStatus.Abandoned, value: 0, color: '#d993ed', icon: 'material-symbols:tab-close-right-outline'},
];

export const returnAbbrName = (name: string) => {
  return name === 'year' ? 'Year to Date' : name === 'month' ? 'Month to Date' : 'Today';
};

export const removeHTMLTags = (str: string) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(str, 'text/html');
  return parsedHtml.body.textContent || '';
};

export const stringToNumber = (value: string | number) => {
  return typeof value === 'string' && value != '' ? parseInt(value) : '';
};

export const hasRankedUserName = (rankedUserName: string) => (rankedUserName === '' ? false : true);

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US');
};

export const formError = () => {
  toastError('Error', 'Please complete all required fields before clicking Save.', 4000);
};

export const addClearButtonToSelect = (field: Fields) => {
  return field;
};

export function isValidYouTubeEmbedLink(node: FormKitNode) {
  const url = node.value as string;
  const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return pattern.test(url);
}

interface Corner {
  lat: number;
  lng: number;
}

interface BoundingBox {
  swCorner: Corner;
  neCorner: Corner;
}

export function createBoundingBox(latitude: number, longitude: number, radius = 17000): BoundingBox {
  const RADIAN_UNIT = 57.2957795;
  const EARTH_RADIUS_IN_KM = 6371.0;

  let radiusInKM: number;
  if (radius >= 1000) {
    radiusInKM = radius / 1000.0;
  } else {
    radiusInKM = radius;
  }

  const latDelta = radiusInKM / EARTH_RADIUS_IN_KM;
  const lonDelta = Math.asin(Math.sin(latDelta) / Math.cos(latitude / RADIAN_UNIT));

  const minLat = latitude - latDelta;
  const maxLat = latitude + latDelta;
  const minLon = longitude - lonDelta;
  const maxLon = longitude + lonDelta;

  return {
    swCorner: { lat: minLat, lng: minLon },
    neCorner: { lat: maxLat, lng: maxLon },
  };
}

// export function toTitleCase(str: unknown): string {
//   return `${str}`.replace(/\w\S*/g, function (txt: string): string {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// }

export function toTitleCase(str: unknown): string {
  if (str === undefined || str === '') {
    return '';
  }

  return `${str}`.replace(/\b\w+\b/g, function (txt: string): string {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
}

export const convertTime = (time: string) => {
  if (time === '') return time;

  const [hour, minute] = time.split(':');
  const h = parseInt(hour) % 12 || 12;
  const ampm = parseInt(hour) < 12 || parseInt(hour) === 24 ? 'AM' : 'PM';
  return `${h}:${minute} ${ampm}`;
};

export const formatDatetoMDY = (date: string) => {
  const dt = DateTime.fromJSDate(new Date(date));
  return dt.toFormat('M/d/yyyy');
};
export const formatDatetoMDYFromString = (date: string) => {
  if (date === undefined || date === null || date === '') return '';
  const dt = DateTime.fromISO(date);
  return dt.toFormat('M/d/yyyy');
};
export const convertDayFromNumberToAbbr = (day: number) => {
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

export const extractHourMinute = (timeStr: string): string | null => {
  const match = timeStr.match(/^(\d{2}:\d{2}):\d{2}$/);
  if (match) {
    return match[1];
  }
  return null;
};

export const getRangeDateStartNowAddMonths = (months: number) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the first day of the current month
  const startOfMonth = new Date(currentYear, currentMonth, 1);

  // Get the last day of the current month
  const endOfMonth = new Date(currentYear, currentMonth + months, 0);

  // Format the dates as ISO strings
  return {
    startDate: startOfMonth.toISOString(),
    endDate: endOfMonth.toISOString(),
  };
};

export const formatToMonth = (d: string, format = 'LLL d') => {
  if (!d || d.length === 0 || d === '') return '';
  const parsedDate = DateTime.fromJSDate(new Date(d));
  if (!parsedDate.isValid) return '';
  return parsedDate.toFormat(format);
};

export const luxonFormatDate = (d: string, format = 'LLL d') => {
  if (!d || d.length === 0 || d === '') return '';
  if (d.includes('/')) d = d.replace(/\//g, '-');
  const parsedDate = DateTime.fromFormat(d, 'yyyy-MM-dd', { zone: 'UTC' });
  if (!parsedDate.isValid) return '';
  return parsedDate.toFormat(format);
};

export const midnight = (d: string, format = 'LLL d') => {
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetHours = -offsetMinutes / 60; // We use negative because the method returns minutes behind UTC, but we usually express it as hours ahead/behind.

  console.log(offsetHours);

  if (!d || d.length === 0 || d === '') return '';
  const parsedDate = DateTime.fromJSDate(new Date(d), { zone: 'UTC' });
  if (!parsedDate.isValid) return '';
  return parsedDate
    .startOf('day')
    .plus({ hour: offsetHours * -1 })
    .toFormat(format);
};

export const getAppointmentNotePrefix = (
  appointmentTypes: string[],
  submittedAt: string | null,
  createdAt: string,
  lang = 'en'
) => {
  const date = submittedAt ?? createdAt;
  if (lang === 'es') {
    const action = submittedAt ? 'envió' : 'comenzó';

    if (appointmentTypes.length === 0)
      return `Esto se refiere a la solicitud de asistencia que usted ${action} el ${formatDatetoMDY(date)}.`;

    const spanishType: { [key: string]: string } = {
      'Utility: Water': 'Servicio: Agua',
      'Utility: Gas/Propane': 'Servicio: Gas/Propano',
      'Utility: Electricity': 'Servicio: Electricidad',
      Water: 'Agua',
      'Gas/Propane': 'Gas/Propano',
      Electricity: 'Electricidad',
      Rent: 'Renta',
      Mortgage: 'Hipoteca',
      'Women’s Shelter': 'Refugio para Mujeres',
      'Men’s Shelter': 'Refugio para Hombres',
      'Women’s and Children Shelter': 'Refugio para Mujeres y Niños',
      'Family Shelter': 'Refugio Familiar',
      'Food Pantry': 'Despensa de Alimentos',
      'Soup Kitchen': 'Comedor Social',
      'Bus Ticket': 'Billete de Autobús',
      'Gas Money': 'Dinero para Gasolina',
      'Vehicle Repair': 'Reparación de Vehículos',
      'Clothing Voucher': 'Vale de Ropa',
      'Appliance repair/purchase': 'Reparación/Compra de Electrodomésticos',
    };

    if (appointmentTypes.length === 1)
      return `Esto concierne a la solicitud de asistencia para ${
        spanishType[appointmentTypes[0]]
      } que usted ${action} el ${formatDatetoMDY(date)};`;

    if (appointmentTypes.length > 1)
      return `Esto concierne a la solicitud de asistencia que usted ${action} el ${formatDatetoMDY(
        date
      )} (para ${appointmentTypes.map((_) => spanishType[_]).join(', ')}).`;

    return `Esta es una solicitud de asistencia con ${appointmentTypes.map((_) => spanishType[_]).join(', ')}.`;
  } else {
    const action = submittedAt ? 'submitted' : 'began';

    if (appointmentTypes.length === 0)
      return `This concerns the application for assistance that you ${action} on ${formatDatetoMDY(date)}.`;

    if (appointmentTypes.length === 1)
      return `This concerns the application for ${appointmentTypes[0]} assistance that you ${action} on ${formatDatetoMDY(
        date
      )}.`;

    if (appointmentTypes.length > 1)
      return `This concerns the application for assistance that you ${action} on ${formatDatetoMDY(
        date
      )} (for ${appointmentTypes.join(', ')}).`;

    return `This is a request for assistance with ${appointmentTypes.join(', ')}.`;
  }
};

export const minutesToHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';

  if (hours > 0) {
    result += hours + ' hour' + (hours > 1 ? 's' : '');
  }

  if (remainingMinutes > 0) {
    if (result) result += ' ';
    result += remainingMinutes + ' minute' + (remainingMinutes > 1 ? 's' : '');
  }

  return result || '0 minutes';
};

export const fromISOToStringUsingTimezone = (date: string, timezone: string, format: string): string => {
  return DateTime.fromISO(date, { zone: 'utc' }).setZone(timezone).toFormat(format);
};

export const fromISOToString = (date: string, format: string): string => {
  return DateTime.fromISO(date).toFormat(format);
};

export const formatPhoneNumber = (input: string): string | null => {
  if (input === null || input === undefined || input.length === 0) return '';
  // Remove any non-numeric characters from the input
  const cleanedInput = input.replace(/\D/g, '');

  // Check if the cleaned input has exactly 10 digits (standard for US phone numbers)
  if (cleanedInput.length !== 10) {
    return null; // or throw an error or handle it as you see fit
  }

  // Extract parts of the number
  const areaCode = cleanedInput.substring(0, 3);
  const centralOfficeCode = cleanedInput.substring(3, 6);
  const lineNumber = cleanedInput.substring(6, 10);

  // Format and return
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

export const convertPhone = (str: string) => {
  const regex = /Phone Number: \[(\d{3})(\d{3})(\d{4})\]/;
  return str.replace(regex, (_, areaCode, first3, last4) => {
    return `Phone Number: [(${areaCode}) ${first3}-${last4}]`;
  });
};

export const removeFormattingFromPhoneNumber = (input: string): string => {
  return input.replace(/\D/g, '');
};

// format the date to be midnight in the user's timezone
export const formatDateToMidnightInUserTimezone = (value: string) => {
  if (value !== undefined && value !== null && value !== '') {
    const date = new Date(value);
    // add the offset to the selected date to make midnight in the user's timezone
    // first get the user's timezone from the browser
    const offset = date.getTimezoneOffset();
    // add the offset (in minutes) to the selected date
    date.setMinutes(date.getMinutes() + offset);
    return date.toISOString();
  } else {
    return value;
  }
};

// format dates from an ISOString to YYYY-MM-DD
export const formatDateForDisplay = (value: string) => {
  if (value !== undefined && value !== null && value !== '') {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  } else {
    return value;
  }
};

export const faqGetSectionId = (section: EnumFaqSection): string => {
  const config = useRuntimeConfig();
  switch (section) {
    case EnumFaqSection.Application:
      return config.public.faqApplicationSectionId;
    case EnumFaqSection.Applicant:
      return config.public.faqApplicantSectionId;
    case EnumFaqSection.Dashboard:
      return config.public.faqDashboardSectionId;
    case EnumFaqSection.FundingSource:
      return config.public.faqFundingSourceSectionId;
    case EnumFaqSection.Fund:
      return config.public.faqFundSectionId;
    case EnumFaqSection.Appointment:
      return config.public.faqAppointmentSectionId;
    case EnumFaqSection.ServiceArea:
      return config.public.faqServiceAreaSectionId;
    default:
      return '';
  }
};

export const isPayeeConsent = (name: string) => {
  return name === 'Verification.png' || name.includes('Payee Consent');
};

export const checkSaHelpEmail = (email: string, empty = true) => {
  const config = useRuntimeConfig();
  if (email.includes(config.public.applicantFakeEmail as string)) {
    if (empty) return '';

    return 'No Email Address';
  }

  return email;
};

export const generateFoodPantryUrl = (data: any) => {
  const config = useRuntimeConfig();
  return `${config.public.publicUrl}/fd/${data.ctr}`;
};

export const getFoodAssistanceTypeId = () => {
  const config = useRuntimeConfig();
  return config.public.foodAssistanceTypeId;
};

export const localDateString = (date: string | undefined | null) => {
  if (date === '' || date === undefined || date === null) return '';

  const dateObj = new Date(date);
  return dateObj.toLocaleDateString();
};

export const reportOptions = [
  { label: 'Applicant ID', value: 'applicantIdPublic' },
  { label: 'Service Area', value: 'serviceAreaName' },
  { label: 'Assistance Request ID', value: 'idPublic' },
  { label: 'Fund', value: 'funds' },
  { label: 'Application ID', value: 'applicationIdPublic' },
  { label: 'Funding Source', value: 'fundingSources' },
  { label: 'Language', value: 'language' },
  { label: 'Assistance Type', value: 'assistanceType' },
  { label: 'Name', value: 'name' },
  { label: 'Amount Requested', value: 'amountRequested' },
  { label: 'Address', value: 'address1' },
  { label: 'Amount Approved', value: 'amountApproved' },
  { label: 'Phone', value: 'phoneNumber' },
  { label: 'Payee', value: 'payee' },
  { label: 'Email Address', value: 'emailAddress' },
  { label: 'Status', value: 'status' },
  { label: 'Household Size', value: 'householdSize' },
  { label: 'Created Date', value: 'createdDate' },
  { label: 'Date of Birth', value: 'dateOfBirth' },
  { label: 'Expiration Date', value: 'expirationDate' },
  { label: 'Gender', value: 'gender' },
  { label: 'Next Eligible Date', value: 'nextEligibleDate' },
  { label: 'Ethnicity', value: 'ethnicity' },
  { label: '# Food Distributions', value: 'foodDistributions' },
  { label: 'Race', value: 'race' },
  { label: 'Last Updated Date', value: 'lastUpdatedDate' },
  { label: 'Marital Status', value: 'maritalStatus' },
  { label: 'Assigned To', value: 'assignedTo' },
  { label: 'Veteran', value: 'veteran' },
  { label: 'Amount Awarded', value: 'amountAwarded' },
  { label: 'Assistance Type ID', value: 'assistanceTypeId' },
  { label: 'Created At', value: 'createdAt' },
  { label: 'ID', value: 'ID' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Licensed Location ID', value: 'licensedLocationId' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'Service Area ID', value: 'serviceAreaId' },
  { label: 'Housing Type', value: 'housingType' },
  { label: 'Is Housing Less Than Seven Days', value: 'isHousingLessThanSevenDays' },
  { label: 'Future Plan', value: 'futurePlan' },
  { label: 'Additional Information', value: 'additionalInformation' },
  { label: 'Did Stay Street Shelter', value: 'didStayStreetShelter' },
  { label: 'Is Domestic Violence Victim', value: 'isDomesticViolenceVictim' },
  { label: 'Has Development Disability', value: 'hasDevelopmentDisability' },
  { label: 'Has Physical Disability', value: 'hasPhysicalDisability' },
  { label: 'Has Mental Health', value: 'hasMentalHealth' },
  { label: 'Has Drug Abuse', value: 'hasDrugAbuse' },
  { label: 'Has Chronic Health Condition', value: 'hasChronicHealthCondition' },
  { label: 'Alcohol Abuse', value: 'alcoholAbuse' },
  { label: 'Number of Bedrooms', value: 'numBedrooms' },
  { label: 'Is Everyone on Lease', value: 'isEveryoneOnLease' },
  { label: 'Is Alcohol Long Term', value: 'isAlcoholLongTerm' },
  { label: 'Is Chronic Long Term', value: 'isChronicLongTerm' },
  { label: 'Is Drug Long Term', value: 'isDrugLongTerm' },
  { label: 'Is Mental Long Term', value: 'isMentalLongTerm' },
  { label: 'Is Physical Long Term', value: 'isPhysicalLongTerm' },
  { label: 'Has HIV', value: 'hasHiv' },
  { label: 'Is Fleeing Domestic', value: 'isFleeingDomestic' },
  { label: 'Pathway of Hope', value: 'pathwayOfHope' },
  { label: 'Full SSN Document', value: 'fullSsnDocument' },
  { label: 'Full SSN', value: 'fullSsn' },
  { label: 'Confirm SSN', value: 'confirmSsn' },
  { label: 'Employed', value: 'employed' },
  { label: 'Employer', value: 'employer' },
  { label: 'Earned Income', value: 'earnedIncome' },
  { label: 'Earned Income Document', value: 'earnedIncomeDocument' },
  { label: 'Unemployment Insurance', value: 'unemploymentInsurance' },
  { label: 'Unemployment Insurance Document', value: 'unemploymentInsuranceDocument' },
  { label: 'Supplemental Security Income', value: 'supplementalSecurityIncome' },
  { label: 'Supplemental Security Income Document', value: 'supplementalSecurityIncomeDocument' },
  { label: 'Social Security Disability Insurance', value: 'socialSecurityDisabilityInsurance' },
  { label: 'Social Security Disability Insurance Document', value: 'socialSecurityDisabilityInsuranceDocument' },
  { label: 'VA Service Connected Disability Compensation', value: 'VAServiceConnectedDisabilityCompensation' },
  {
    label: 'VA Service Connected Disability Compensation Document',
    value: 'VAServiceConnectedDisabilityCompensationDocument',
  },
  { label: 'VA Non-Service Connected Disability Pension', value: 'VANonServiceConnectedDisabilityPension' },
  { label: 'VA Non-Service Connected Disability Pension Document', value: 'VANonServiceConnectedDisabilityPensionDocument' },
  { label: 'Private Disability Insurance', value: 'privateDisabilityInsurance' },
  { label: 'Private Disability Insurance Document', value: 'privateDisabilityInsuranceDocument' },
  { label: 'Workers Compensation', value: 'workersCompensation' },
  { label: 'Workers Compensation Document', value: 'workersCompensationDocument' },
  { label: 'Temporary Assistance for Needy Families', value: 'temporaryAssistanceForNeedyFamilies' },
  { label: 'Temporary Assistance for Needy Families Document', value: 'temporaryAssistanceForNeedyFamiliesDocument' },
  { label: 'General Assistance', value: 'generalAssistance' },
  { label: 'General Assistance Document', value: 'generalAssistanceDocument' },
  { label: 'Retirement Income from Social Security', value: 'retirementIncomeFromSocialSecurity' },
  { label: 'Retirement Income from Social Security Document', value: 'retirementIncomeFromSocialSecurityDocument' },
  { label: 'Pension or Retirement Income from Social Security', value: 'pensionOrRetirementIncomeFromSocialSecurity' },
  {
    label: 'Pension or Retirement Income from Social Security Document',
    value: 'pensionOrRetirementIncomeFromSocialSecurityDocument',
  },
  { label: 'Child Support', value: 'childSupport' },
  { label: 'Child Support Document', value: 'childSupportDocument' },
  { label: 'Alimony and Other Spousal Support', value: 'alimonyAndOtherSpousalSupport' },
  { label: 'Alimony and Other Spousal Support Document', value: 'alimonyAndOtherSpousalSupportDocument' },
  { label: 'Other Monetary Sources', value: 'otherMonetarySources' },
  { label: 'Other Monetary Sources Document', value: 'otherMonetarySourcesDocument' },
  { label: 'Specify Other Monetary Sources', value: 'specifyOtherMonetarySources' },
  { label: 'Supplemental Nutrition Assistance Program', value: 'supplementalNutritionAssistanceProgram' },
  { label: 'Supplemental Nutrition Assistance Program Document', value: 'supplementalNutritionAssistanceProgramDocument' },
  {
    label: 'Special Supplemental Nutrition Program for Women, Infants, and Children',
    value: 'specialSupplementalForWomenInfantsAndChildren',
  },
  {
    label: 'Special Supplemental Nutrition Program for Women, Infants, and Children Document',
    value: 'specialSupplementalForWomenInfantsAndChildrenDocument',
  },
  { label: 'TANF Child Care Services', value: 'TANFChildCareServices' },
  { label: 'TANF Child Care Services Document', value: 'TANFChildCareServicesDocument' },
  { label: 'TANF Transportation Services', value: 'TANFTransportationServices' },
  { label: 'TANF Transportation Services Document', value: 'TANFTransportationServicesDocument' },
  { label: 'Other TANF Funded Services', value: 'otherTANFFundedServices' },
  { label: 'Other TANF Funded Services Document', value: 'otherTANFFundedServicesDocument' },
  { label: 'Other Non-Monetary Sources', value: 'otherNonMonetarySources' },
  { label: 'Other Non-Monetary Sources Document', value: 'otherNonMonetarySourcesDocument' },
  { label: 'Specify Other Non-Monetary Sources', value: 'specifyOtherNonMonetarySources' },
  { label: 'Utility Provider State', value: 'utilityProviderState' },
  { label: 'Utility Provider Name', value: 'utilityProviderName' },
  { label: 'Utility Provider Zip', value: 'utilityProviderZip' },
  { label: 'Utility Provider Phone', value: 'utilityProviderPhone' },
  { label: 'Utility Provider Email', value: 'utilityProviderEmail' },
  { label: 'Utility Account Number', value: 'utilityAccountNumber' },
  { label: 'Utility Invoice Number', value: 'utilityInvoiceNumber' },
  { label: 'Utility Amount Past Due', value: 'utilityAmountPastDue' },
  { label: 'Utility Next Due Date', value: 'utilityNextDueDate' },
  { label: 'Utility Past Due Billing Cycles', value: 'utilityPastDueBillingCycles' },
  { label: 'Have You Received a Shutoff Notice', value: 'haveYouReceivedAShutoffNotice' },
  { label: 'If Yes, Date of Shutoff', value: 'ifYesDateOfShutoff' },
  { label: 'Utility Document', value: 'utilityDocument' },
  { label: 'Utility Provider Address', value: 'utilityProviderAddress' },
  { label: 'Utility Provider City', value: 'utilityProviderCity' },
  { label: 'Utility Payment', value: 'utilityPayment' },
  { label: 'Landlord Name', value: 'landlordName' },
  { label: 'Landlord State', value: 'landlordState' },
  { label: 'Landlord City', value: 'landlordCity' },
  { label: 'Landlord Address', value: 'landlordAddress' },
  { label: 'Landlord Zip', value: 'landlordZip' },
  { label: 'Landlord Phone', value: 'landlordPhone' },
  { label: 'Landlord Invoice Number', value: 'landlordInvoiceNumber' },
  { label: 'Landlord Email', value: 'landlordEmail' },
  { label: 'Monthly Rent Amount (New)', value: 'monthlyRentAmountNew' },
  { label: 'Rent Amount Past Due (New)', value: 'rentAmountPastDueNew' },
  { label: 'Rent Next Due Date', value: 'rentNextDueDate' },
  { label: 'Rent Months Past Due', value: 'rentMonthsPastDue' },
  { label: 'Have You Received an Eviction Notice', value: 'haveYouReceivedAnEvictionNotice' },
  { label: 'If Yes, Date of Eviction', value: 'ifYesDateOfEviction' },
  { label: 'Have You Received an Eviction Notice Document', value: 'haveYouReceivedAnEvictionNoticeDocument' },
  { label: 'Lender Name', value: 'lenderName' },
  { label: 'Lender Address', value: 'lenderAddress' },
  { label: 'Lender City', value: 'lenderCity' },
  { label: 'Lender State', value: 'lenderStateNew' },
  { label: 'Lender Zip', value: 'lenderZip' },
  { label: 'Lender Phone', value: 'lenderPhone' },
  { label: 'Lender Invoice Number', value: 'lenderInvoiceNumber' },
  { label: 'Lender Email', value: 'lenderEmail' },
  { label: 'Monthly Mortgage Amount', value: 'monthlyMortgageAmount' },
  { label: 'Mortgage Amount Past Due (New)', value: 'mortgageAmountPastDueNew' },
  { label: 'Mortgage Months Past Due', value: 'mortgageMonthsPastDue' },
  { label: 'Have You Received a Foreclosure Notice', value: 'haveYouReceivedAForeclosureNotice' },
  { label: 'If Yes, Date of Foreclosure', value: 'ifYesDateOfForeclosure' },
  { label: 'Have You Received a Foreclosure Notice Document', value: 'haveYouReceivedAForeclosureNoticeDocument' },
  { label: 'Have You Received a Foreclosure Notice Document (New)', value: 'haveYouReceivedAForeclosureNoticeDocumentNew' },
  { label: 'Lease Agreement Document', value: 'leaseAgreementDocument' },
  { label: 'Identification Document', value: 'identificationDocument' },
  { label: 'Medicaid', value: 'medicaidNew' },
  { label: 'Specify Other Coverage', value: 'specifyOtherCoverage' },
  { label: "State Children's Health Insurance", value: 'stateChildrensHealthInsurance' },
  { label: 'Health Insurance Obtained Through COBRA', value: 'healthInsuranceObtainedThroughCobra' },
  { label: 'Indian Health Services Program', value: 'indianHealthServicesProgram' },
  { label: 'Medicare', value: 'medicare' },
  { label: 'Veterans Administration Medical Services', value: 'veteransAdministrationMedicalServices' },
  { label: 'Veterans Other Coverage', value: 'veteransOtherCoverage' },
  { label: 'Specify Other Veterans Coverage', value: 'specifyOtherVeteransCoverage' },
  { label: 'Private Health Insurance', value: 'privateHealthInsurance' },
  { label: 'Employer Provided Health Insurance', value: 'employerProvidedHealthInsurance' },
  { label: 'State Health Insurance for Adults', value: 'stateHealthInsuranceForAdults' },
  { label: 'State Health Other Coverage', value: 'stateHealthOtherCoverage' },
  { label: 'Other Amount Requested', value: 'otherAmountRequested' },
  { label: 'Other Supporting Documentation', value: 'otherSupportingDocumentation' },
  { label: 'Have You Received a Shutoff Notice Document', value: 'haveYouReceivedAShutoffNoticeDocument' },
  { label: 'Have You Received a Shutoff Notice Document (New)', value: 'haveYouReceivedAShutoffNoticeDocumentNew' },
  { label: 'Mortgage Document', value: 'mortgageDocument' },
  { label: 'Suffix', value: 'suffix' },
  { label: 'Relationship to Head of Household', value: 'relationshipToHeadOfHousehold' },
  { label: 'Pregnancy Due Date', value: 'pregnancyDueDate' },
  { label: 'Reason for Assistance', value: 'reasonForAssistance' },
  { label: 'Food Pantries', value: 'foodPantries' },
  { label: 'Address 1', value: 'address' },
  { label: 'Address 2', value: 'address2' },
  { label: 'City', value: 'city' },
  { label: 'State', value: 'state' },
  { label: 'Phone', value: 'phone' },
  { label: 'County', value: 'county' },
  { label: 'Country Code', value: 'countryCode' },
  { label: 'Text Opt-In', value: 'textOptIn' },
  { label: 'Middle Name', value: 'middleName' },
  { label: 'Pregnancy Status', value: 'pregnancyStatus' },
  { label: 'Referred By', value: 'referredBy' },
  { label: 'Other Describe Your Need', value: 'otherDescribeYourNeed' },
  { label: 'Consent Type', value: 'consentType' },
  { label: 'Is Homeless/Formerly Homeless', value: 'isHomelessFormerlyHomeless' },
  { label: 'Agree', value: 'agree' },
  { label: 'Landlord Address 2', value: 'landlordAddress2' },
  { label: 'Non-Monetary Other Sources Document', value: 'nonMonetaryOtherSourcesDocument' },
  { label: 'Other Supporting Documentation 2', value: 'otherSupportingDocumentation2' },
  { label: 'Mortgage Next Due Date', value: 'mortgageNextDueDate' },
  { label: 'Digital Sig ID Personal Info', value: 'digitalSigIdPersonalInfo' },
  { label: 'Digital Sig Image Desc ID Personal Info', value: 'digitalSigImageDescIdPersonalInfo' },
  { label: 'Applicant ID (Internal)', value: 'applicantId' },
];

export const reportFieldValues = async () => {
  const dynamicFormStore = useDynamicFormStore();
  const { data: dataNamesByTerritory } = await dynamicFormStore.getNamesByTerritory();

  if (dataNamesByTerritory != null) {
    return dataNamesByTerritory;
  }

  return [];
};

export const exportCsv = async (keys: any, data: any, rolesData: any) => {
  const csvData: any[] = [];

  const emptyLine: { [key: string]: any } = {};
  keys.forEach((k: any) => {
    emptyLine[k] = '';
  });

  const line = JSON.parse(JSON.stringify(emptyLine));
  line[keys[0]] = 'Report';
  csvData.push(line);

  csvData.push(emptyLine);
  csvData.push(emptyLine);

  const headers: { [key: string]: string } = {};
  const reportFieldValuesResult = await reportFieldValues();

  const j = 0;
  for (const i in keys) {
    headers[keys[i]] = reportFieldValuesResult.find((_) => keys[i] === _.value)?.label ?? '';
  }
  csvData.push(headers);

  data.forEach((row: any) => {
    const newRow: { [key: string]: any } = {};

    keys.forEach((k: any) => {
      if (k === 'firstName') {
        if (row[k]) newRow[k] = row['firstName'] + ' ' + row['lastName'];
      } else if (k === 'address') {
        newRow[k] = `${row['address1']}, ${row['address2'] ? row['address2'] + ',' : ''} ${row['city']}, ${row['state']} ${
          row['zipcode']
        }`;
      } else if (k === 'amountRequested' || k === 'amountApproved' || k === 'amountAwarded') {
        newRow[k] = formatNumberToMoney(convertFromCents(row[k]));
      } else if (k === 'createdDate' || k === 'lastUpdatedDate' || k === 'expirationDate' || k === 'nextEligibleDate') {
        newRow[k] = formatDateString(row[k], 'M/d/yyyy');
      } else if (k === 'status') {
        newRow[k] = EnumAssistanceRequestStatusUserFriendlyJson[row[k]];
      } else if (k === 'applicantIdPublic') {
        newRow[k] = generateApplicantId(row['applicantIdPublic']);
      } else if (k === 'applicationIdPublic') {
        newRow[k] = generateApplicationId(row['applicationIdPublic']);
      } else if (k === 'idPublic') {
        newRow[k] = generateAssistanceRequestId(row['idPublic']);
      } else if (k === 'assignedTo') {
        const getCurrentRole = (roleId: number) => {
          if (rolesData.value && roleId > 0) {
            const role: Role | undefined = rolesData?.find((role: any) => role.roleId === roleId);
            return role ? (hasRankedUserName(role?.rankedUserName ?? '') ? role?.rankedUserName : '') : 'Unassigned';
          }
          return 'Unassigned';
        };
        newRow[k] = getCurrentRole(row['assignedTo']);
      } else {
        newRow[k] = row[k];
      }
    });
    csvData.push(newRow);
  });

  const csv = Papa.unparse(csvData, {
    header: false, // Set this option to false to exclude the header row
  });

  const blob = new Blob([csv], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  // create a filename with a timestamp
  link.download = `report.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
